<template>
    <div id="app">
        <div
            v-if="permissionsLoaded && hasAccess"
            class="container-fluid"
        >
            <div class="row flex-nowrap">
                <div class="col-auto px-0">
                    <Menu :default-menu="null">
                        <template #section-one-heading="{collapsed}">
                            <MenuHeading
                                title="Paglipat"
                                :icon="['far', 'clipboard-list-check']"
                                :collapsed="collapsed"
                            />
                        </template>

                        <template #section-one-items="{collapsed}">
                            <MenuItem
                                :to="{name: 'transfer.list'}"
                                icon="list"
                                :collapsed="collapsed"
                                title="Transfers"
                            />

                            <MenuItem
                                :to="{name: 'transfer.activity.list'}"
                                icon="chart-line"
                                :collapsed="collapsed"
                                title="Activity"
                            />
                        </template>

                        <template #section-two-heading="{collapsed}">
                            <MenuHeading
                                title="IMT Platform"
                                icon="cube"
                                :collapsed="collapsed"
                            />
                        </template>

                        <template #section-two-items="{collapsed}">
                            <MenuItem
                                v-for="item in platformMenuItems"
                                :key="item.title"
                                :href="item.href"
                                :icon="item.icon"
                                :collapsed="collapsed"
                                :title="item.title"
                            />
                        </template>
                    </Menu>
                </div>

                <div class="col px-0 d-flex flex-column">
                    <Header
                        :title="pageTitle"
                        @toggled-mode="toggleMode($event === true ? 'dark' : 'light')"
                    />

                    <RouterView
                        :mode="mode"
                        @set-title="pageTitleOverride = $event"
                        @toggle-mode="toggleMode($event)"
                    />

                    <div class="row footer m-0 mt-auto">
                        <div class="col-4">
                            <a
                                href="https://ticket.imtins.com/status/endpoints"
                                class="btn btn-link p-0"
                            >
                                Platform Status
                            </a>
                        </div>

                        <div class="col-12 text-muted text-center">
                            <span class="copyright">
                                &copy;{{ copyrightText }}
                            </span>
                        </div>

                        <div class="col-12 text-right">
                            <button
                                ref="backToTop"
                                v-scroll-to="'#app'"
                                class="btn btn-link btn-back-to-top p-0"
                            >
                                <FontAwesomeIcon
                                    icon="caret-up"
                                />

                                Back to top
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Forbidden
            v-else-if="permissionsLoaded && !hasAccess"
            class="vh-100 justify-content-center"
        />
    </div>
</template>

<script>
    import Header from '@imt/vue-admin-menus/src/components/Header.vue';
    import Menu from '@imt/vue-admin-menus/src/components/Menu.vue';
    import MenuHeading from '@imt/vue-admin-menus/src/components/MenuHeading.vue';
    import MenuItem from '@imt/vue-admin-menus/src/components/MenuItem.vue';
    import platformAdminMenuItems from '@imt/vue-admin-menus/src/menus';
    import modeMixin from '@imt/vue-kit-car/src/mixins/modes';
    import dayjs from 'dayjs';

    import authMixin from '@/mixins/auth';

    export default {
        name: 'App',
        components: {
            Header,
            Menu,
            MenuHeading,
            MenuItem,
            Forbidden: () => import('@imt/vue-kit-car/src/components/errors/Forbidden.vue'),
        },
        mixins: [
            authMixin,
            modeMixin,
        ],
        metaInfo() {
            return {
                title: this.pageTitle,
                titleTemplate: '%s | Paglipat Admin | IMT Insurance',
            };
        },
        data() {
            return {
                pageTitleOverride: '',
            };
        },
        computed: {
            copyrightText: () => `Copyright ${dayjs().year()} IMT Insurance. All rights reserved.`,
            darkMode() {
                return this.mode === 'dark';
            },
            pageTitle() {
                return this.user.id && this.$route.meta.title
                    ? this.hasAccess
                        ? this.pageTitleOverride || this.$route.meta.title
                        : 'Permission Denied'
                    : 'Loading';
            },
            platformMenuItems() {
                return platformAdminMenuItems().filter(item => {
                    return item.section === 1 && item.title.toLowerCase() !== 'workflow';
                });
            },
        },
        watch: {
            $route(to, from) {
                if (to.path !== from.path) {
                    this.pageTitleOverride = '';
                }
            },
        },
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/kit-car"

    .paglipat-loading-component
        width: 100vw
        height: 100vh
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        font-family: Arial, Helvetica, sans-serif

        .vld-icon
            display: flex
            justify-content: center
            align-items: center
            flex-direction: column
</style>

import notifications from '@imt/vue-admin-menus/src/store/notifications';
import list from '@imt/vue-list/src/store';
import toolbox from '@imt/vue-toolbox/src/store';
import utils, {axiosWithAuth} from '@imt/vue-toolbox/src/utils';
import Vue from 'vue';
import Vuex from 'vuex';

import builder from '@/store/builder';

Vue.use(Vuex);

export const authedAxios = axiosWithAuth();

export const getters = {};

export const actions = {
    async fetchActivity(context, {
        transferId = null,
        limit = null,
        page = null,
    }) {
        let url = '/activity/?include=created_by';

        url += transferId ? `&filter[transfer_id]=${transferId}` : '';
        url += page ? `&page=${page}` : '';
        url += limit ? `&page[size]=${limit}` : '';

        const response = await authedAxios.get(`${url}`),
            paging = response.data.meta.pagination;
        paging.pageSize = response.data.data.length;
        paging.links = response.data.links;

        return {
            activity: utils.dataFormatter.deserialize(response.data),
            paging,
        };
    },
    async fetchTransfers(context, query = '') {
        const response = await authedAxios.get(`/transfers/?${query}${context.getters['list/queryString']('transfers')}`),
            formattedData = utils.dataFormatter.deserialize(response.data);

        context.commit('builder/SET_DATA', {
            field: 'steps',
            data: [],
        });

        context.commit('SET_DATA', {
            field: 'transfers',
            data: formattedData,
        });

        let paging = response.data.meta.pagination;
        paging.pageSize = response.data.data.length;
        paging.links = response.data.links;
        context.commit('list/SET_PAGING', {
            adminKey: 'transfers',
            paging,
        });

        return formattedData;
    },
    async fetchTransfer(context, transferId) {
        const response = await authedAxios.get(`/transfers/${transferId}/?include=steps`),
            formattedData = utils.dataFormatter.deserialize(response.data);

        context.commit('SET_DATA', {
            field: 'transfer',
            data: formattedData,
        });

        context.commit('builder/SET_DATA', {
            field: 'steps',
            data: formattedData.steps,
        });

        return formattedData;
    },
};

export const mutations = {
    SET_DATA(state, {
        field,
        data,
    }) {
        state[field] = data;
    },
};

export const state = () => {
    return {
        transfer: {},
        transfers: [],
    };
};

export default new Vuex.Store({
    modules: {
        builder,
        list,
        notifications,
        toolbox,
    },
    actions,
    getters,
    mutations,
    state: state(),
});

import utils, {axiosWithAuth} from '@imt/vue-toolbox/src/utils';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const authedAxios = axiosWithAuth();

export const actions = {
    async saveTransfer(context, transfer) {
        let method = 'patch';

        if (!transfer.id) {
            method = 'post';
            transfer.department = 'information_systems';
            transfer.notes = 'notes';
        }

        const response = await authedAxios[method](`/transfers/${transfer.id ? `${transfer.id}/` : ''}`, utils.dataFormatter.serialize({
            stuff: transfer,
        }));

        let transferId = utils.dataFormatter.deserialize(response.data).id;

        await context.dispatch('saveTransferSteps', transferId);

        return transferId;
    },
    async deleteTransfer(context, transferId) {
        return await authedAxios.delete(`/transfers/${transferId}/`);
    },
    async runTransfer(context, transferId) {
        return await authedAxios.post(`/transfers/${transferId}/run/`);
    },
    async saveTransferSteps(context, transferId) {
        await context.state.steps.forEach((step, index) => {
            return context.dispatch('saveStep', {
                ...step,
                order: index,
                transferId
            });
        });
    },
    async saveStep(context, step) {
        let method = 'patch';

        if (!step.id) {
            method = 'post';
            step.type = 'Step';
        }

        const response = await authedAxios[method](`/steps/${step.id ? `${step.id}/` : ''}`, utils.dataFormatter.serialize({
            stuff: step,
        }));

        return utils.dataFormatter.deserialize(response.data);
    },
    async deleteStep(context, data) {
        context.commit('REMOVE_STEP', data);

        if (data.step.id) {
            await authedAxios.delete(`/steps/${data.step.id}/`);
        }
    },
};

export const mutations = {
    ADD_STEP(state, step) {
        state.steps.push(step);
    },
    REMOVE_STEP(state, data) {
        state.steps.splice(data.index, 1);
    },
    SET_DATA(state, {field, data}) {
        state[field] = data;
    },
};

export const state = () => {
    return {
        steps: [],
    };
};

export default {
    namespaced: true,
    actions,
    mutations,
    state: state(),
};

import {UUID_REGEX} from '@imt/vue-toolbox/src/utils/index';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: () => import(/* webpackChunkName: "PBase" */ '@/views/PBase.vue'),
            children: [
                {
                    path: '',
                    redirect: {
                        name: 'transfer.list',
                    },
                },
                {
                    path: 'transfers',
                    name: 'transfer.list',
                    meta: {
                        title: 'Transfers',
                    },
                    component: () => import(/* webpackChunkName: "PTransferList" */ '@/views/PTransferList.vue'),
                    children: [],
                },
                {
                    path: 'transfers/new',
                    name: 'transfer.builder.add',
                    meta: {
                        title: 'Transfer Builder',
                    },
                    component: () => import(/* webpackChunkName: "PTransferBuilderNew" */ '@/views/PTransferBuilder.vue'),
                },
                {
                    path: `transfers/:transferId(${UUID_REGEX})/edit`,
                    name: 'transfer.builder.edit',
                    meta: {
                        title: 'Transfer Builder',
                    },
                    component: () => import(/* webpackChunkName: "PTransferBuilderEdit" */ '@/views/PTransferBuilder.vue'),
                },
                {
                    path: `transfers/:transferId(${UUID_REGEX})/activity`,
                    name: 'transfer.activity.list',
                    meta: {
                        title: 'Transfer Activity',
                    },
                    component: () => import(/* webpackChunkName: "PTransferActivityList" */ '@/views/PTransferActivityList.vue'),
                    children: [],
                },
            ],
        },
        // Fallback
        {
            path: '*',
            name: 'not-found',
            meta: {
                title: 'Not Found',
            },
            component: () => import(/* webpackChunkName: "KCNotFound" */ '@imt/vue-kit-car/src/components/errors/NotFound.vue'),
        },
    ],
});

import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faCaretUp,
    faChartLine,
    faCog,
    faList,
    faPlay,
    faPlus,
    faSave,
    faStream,
    faSpinner,
    faTrash,
    faTools,
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
    faCaretUp,
    faChartLine,
    faCog,
    faList,
    faPlay,
    faPlus,
    faSave,
    faSpinner,
    faStream,
    faTrash,
    faTools,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

import {
    ValidationProvider,
    ValidationObserver,
    extend,
    localize,
} from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import {
    required,
    required_if, // eslint-disable-line camelcase
    numeric,
} from 'vee-validate/dist/rules';
import Vue from 'vue';

localize('en', en);

extend('required', {
    ...required,
    message: '{_field_} is required.',
});

extend('numeric', {
    ...numeric,
    message: '{_field_} must be a number.',
});

extend('required_if', required_if);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

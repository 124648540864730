import auth from '@imt/vue-toolbox/src/mixins/auth';
import {mapState} from 'vuex';

export default {
    computed: {
        canManage() {
            return !!this.permissions.transfer_manage;
        },
        hasAccess() {
            return !!this.permissions.paglipat_access;
        },
        permissionsLoaded() {
            return !!this.user.id;
        },
        ...mapState('toolbox', [
            'user',
            'permissions',
        ]),
    },
    mixins: [auth]
};
